<template>
  <v-card
    max-width="340"
    elevation="0"
    class="py-0 my-0 mx-auto"
    color="rgba(255,255,255,0)"
  >
    <v-card-text class="text-center py-3 my-0">
      <span class="grey--text text--darken-1 text-body-2"
        >&copy; LMTGROUP 2021</span
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({})
}
</script>